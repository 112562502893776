<div class="container" style="max-width:1500px">
  <div class="row footer-row">
    <div class="col-0 col-sm-2"></div>
    <div class="col-12 col-sm-4 footer-text" style="text-align: left;">
      <div class="mat-subheading-2">
        Our agency will fulfill all your insurance lead needs whether you are an individual agent/broker or a large agency looking to provide leads to your brokers. Write as much business as you like! Contact us now. Spaces are limited for quality control.
      </div>
    </div>
    <style>
      .footer-contact{
        text-align: left;
        padding:40px;
      }
      .phone-text{
        font-size: x-large;
      }
      .address-text{
        font-size: large;
      }
      .footer-contact a{
        color:white;
      }
    </style>
    <div class="col-12 col-sm-4 footer-contact">
      <span class="phone-text"><a href="tel:18667979736">1 (866) 797-9736</a></span><br>
      <span class="times-text">MON–SAT 10AM–8PM</span><br><br>
      <span class="address-text">1 Yonge St. Suite 1801, Toronto, ON.</span>
    </div>
    <div class="col-0 col-sm-2"></div>

    <div class="col-12 footer-copyright" style="text-align: center;">
      <p>Copyright 2017 | Insurance Lead Master | All Rights Reserved</p>
    </div>
  </div>
  <div class="row">
  </div>
</div>
