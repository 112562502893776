<!-- 
[mode]="(isHandset$ | async) ? 'over' : 'side'" 
[opened]="(isHandset$ | async) === false"
-->
<mat-sidenav-container class="sidenav-container">
  <!-- <mat-sidenav #drawer class="sidenav" 
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : ''"
      [opened]="false"> -->
  <mat-sidenav #drawer [mode]="(isHandset$ | async) ? 'over' : 'over'" closed role="navigation">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="#">Link 1</a>
      <a mat-list-item href="#">Link 2</a>
      <a mat-list-item href="#">Link 3</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar>
      <div class="container" style="max-width: 1500px;">
        <div class="row header-row">
          <div class="col-4">
            <!-- <button
              type="button"
              aria-label="Toggle sidenav"
              mat-icon-button
              (click)="drawer.toggle()"
              *ngIf="isHandset$ | async">
              <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button> -->
            <span><img src="../../assets/img/lead-master-logo.png" alt="" class="img-fluid"></span>
            <!-- <div class="" style="flex: 1 1 auto;"></div> -->
          </div>
          <div class="col-5"></div>
          <div class="col-3"  style="display:flex; align-items: center; justify-content: flex-end;">
              <button
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="drawer.toggle()">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
              </button>
          </div>
        </div>
      </div>
    </mat-toolbar>
    <app-main></app-main>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
